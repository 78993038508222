///
/// @file components/prouducts/_product_add_to_favorites_v1.scss
///
/// \brief Styles for mpp add to favorites button
///
///

.product-add-favorites {
  &:active,
  &:link,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: none;
  }
  .icon {
    width: 17px;
    height: 17px;
  }
}
